export enum EventName {
  ACCORDION_TAB_CLICK = 'Accordion Tab Click',
  FORM_STARTED = 'Form Started',
  FORM_STEP_COMPLETED = 'Form Step Completed',
  FORM_SUBMITTED = 'Form Submitted',
  PRICING_FORM_SINGLE_PATH_EVALUATED = 'Pricing Form Single Path Evaluated',
}

// Name you see on the LaunchDarkly dashboard: front facing name
export enum LaunchDarklyFlagName {
  ROLLOUT_PRICING_FORM_SINGLE_PATH = 'Rollout Pricing Form Single Path',
}

export enum LaunchDarklyFlagValue {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}
